import { Stack } from "@mui/material";
import { useMachine } from "@xstate/react";
import { Navigate, useLocation } from "react-router";
import { useGlobalStore } from "../../store";
import FullscreenLoadingIndicator from "../FullscreenLoadingIndicator";
import CollectUserQueryError from "./components/CollectUserQueryError";
import CollectUserQueryForm from "./components/CollectUserQueryForm";
import CollectUserQuerySuccess from "./components/CollectUserQuerySuccess";
import { collectUserQueryStateMachine } from "./machine";

export default function CollectUserQueryFlow() {
  const [stateMachineState, dispatchStateMachineEvent] = useMachine(
    collectUserQueryStateMachine
  );

  const { state } = useGlobalStore();
  const location = useLocation();

  const loggedInUserQueryDetails = {
    userDetails: {
      firstName:
        stateMachineState.context.userQueryDetails?.firstName ||
        state.currentUser.firstName ||
        "",
      lastName:
        stateMachineState.context.userQueryDetails?.lastName ||
        state.currentUser.lastName ||
        "",
      phoneNumber:
        stateMachineState.context.userQueryDetails?.phoneNumber || {
          countryCode: state.countryCode,
          globalSubscriberNumber: state.currentUser.mobileNumber,
        } ||
        null,
      feedbackMessage:
        stateMachineState.context.userQueryDetails?.feedbackMessage || "",
      emailAddress:
        stateMachineState.context.userQueryDetails?.emailAddress ||
        state.currentUser.emailAddress ||
        "",
      querySourceURI:
        stateMachineState.context?.userQueryDetails?.querySourceURI ||
        location?.state?.eventSource ||
        "",
    },
  };

  function onCollectUserQuerySuccessLogAnotherQuery() {
    dispatchStateMachineEvent({ type: "LOG_ANOTHER_QUERY_BUTTON_CLICKED" });
  }

  function onCollectUserQueryErrorGoBackHome() {
    dispatchStateMachineEvent({ type: "GO_HOME_BUTTON_CLICKED" });
  }

  function onCollectUserQueryErrorTryAgain() {
    dispatchStateMachineEvent({ type: "TRY_AGAIN_BUTTON" });
  }

  function onGoHomeHandler() {
    dispatchStateMachineEvent({ type: "GO_HOME_BUTTON_CLICKED" });
  }

  function onCollectUserQuerySubmit(queryData: any) {
    dispatchStateMachineEvent({
      type: "SUBMIT_BUTTON_CLICKED",
      input: queryData,
    });
  }

  return (
    <Stack p={2}>
      {stateMachineState.matches("collectingUserQueryDetails") && (
        <CollectUserQueryForm
          onSubmit={onCollectUserQuerySubmit}
          data={loggedInUserQueryDetails}
        />
      )}

      {stateMachineState.matches("error") && (
        <Stack sx={{ height: "100vh", justifyContent: "end" }}>
          <CollectUserQueryError
            onGoBackHome={onCollectUserQueryErrorGoBackHome}
            onTryAgain={onCollectUserQueryErrorTryAgain}
          />
        </Stack>
      )}

      {stateMachineState.matches("success") && (
        <Stack sx={{ height: "100vh", justifyContent: "end" }}>
          <CollectUserQuerySuccess
            onGoHome={onGoHomeHandler}
            queryLoggerFirstName={
              stateMachineState.context.userQueryDetails?.firstName
            }
            onLogAnotherQuery={onCollectUserQuerySuccessLogAnotherQuery}
          />
        </Stack>
      )}

      {stateMachineState.matches("submittingUserQueryDetails") && (
        <FullscreenLoadingIndicator />
      )}

      {stateMachineState.matches("exit") && <Navigate to="/" />}
    </Stack>
  );
}
